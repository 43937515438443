.flex-alignCenter {
  align-items: center;
  display: flex;
}

.flex-alignCenter-spaceBetween {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.flex-Start-spaceBetween {
  justify-content: space-between;
  align-items: start;
  display: flex;
}

.flex-column-center {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.gap-10 {
  gap: 10px;
}

.gap-15 {
  gap: 15px;
}

.gap-20 {
  gap: 20px;
}

.gap-25 {
  gap: 25px;
}

.gap-30 {
  gap: 30px;
}

.gap-35 {
  gap: 35px;
}

.gap-40 {
  gap: 40px;
}

.gap-45 {
  gap: 45px;
}

.gap-50 {
  gap: 50px;
}

.marginTop-200 {
  margin-top: 200px;
}

.marginTop-300 {
  margin-top: 300px;
}

.z-index {
  z-index: 9999;
  position: relative;
}

.no-padding {
  padding: 0 !important;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  text-decoration: none;
  list-style: none;
}

.wrapper {
  max-width: 1170px;
  margin: 0 auto;
  overflow: hidden;
}

.wrapper2 {
  max-width: 1600px;
  margin: 0 auto;
}

.wrapper-aboutUs {
  max-width: 1500px;
  margin: 0 auto;
  padding: 0 15px;
  overflow: hidden;
}

.header {
  z-index: 100000;
  color: #000;
  background-color: #fff;
  width: 100%;
  transition: all .3s;
  position: fixed;
  left: 0;
  box-shadow: 0 0 5px #000;
}

.header-menu-btn {
  display: none;
}

.header-menu-btn div {
  border-bottom: 3px solid #000;
  width: 20px;
}

.header.active {
  color: #000;
  background-color: #fff;
  box-shadow: 0 1px 3px #0000001f, 0 1px 2px #0000003d;
}

.header .header-logo {
  width: 100px;
  height: 100px;
}

.header .header-logo img {
  width: 100%;
  height: 100%;
}

.home-page .container-p2 {
  flex-wrap: wrap;
  width: 100%;
  height: 100vh;
  margin-top: 75px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.c {
  width: 100%;
  height: 100%;
  transition: all .8s;
  position: absolute;
}

.home-page .container-p2 img {
  object-fit: cover;
  width: 100%;
  height: auto;
}

.header-menu {
  margin-right: 10px;
}

.header-menu h3 {
  color: #000;
  cursor: pointer;
  border-radius: 5px;
  padding: 5px;
  transition: all .5s;
}

.header-menu a.act h3, .header-menu h3:hover {
  color: #0081bb;
  transform: translateY(-5px);
  box-shadow: 0 10px 10px 1px #000000eb;
}

.home-page .text {
  color: #fff;
  z-index: 10;
  text-align: center;
  background-color: #0000;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@keyframes animate1 {
  from {
    transform: translateX(-100%);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.text h1 {
  z-index: 3;
  font-size: 3rem;
  position: relative;
}

.home-page .img1 .text h1, .home-page .img1 .text p {
  opacity: 0;
  animation-name: animate1;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

.home-page .text p {
  font-size: 1.5rem;
  line-height: 3rem;
}

.home-page .container-p3 {
  margin: 100px 0;
  overflow: hidden;
}

.home-page .container-p3 .content {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.home-page .container-p3 .content .txt {
  flex-direction: column;
  align-items: start;
  gap: 20px;
  width: 40%;
  font-size: 20px;
  display: flex;
}

.home-page .container-p3 .content .txt h2 {
  opacity: 0;
  transition: all 1s .5s;
  transform: translateY(100%);
}

.home-page .container-p3 .content .txt p {
  opacity: 0;
  transition: all 1s;
  transform: translateY(100%);
}

.home-page .container-p3.active .content .txt h2 {
  opacity: 1;
  transform: translateY(0);
}

.home-page .container-p3.active .content .txt p:nth-child(2) {
  opacity: 1;
  transition-delay: .9s;
  transform: translateY(0);
}

.home-page .container-p3.active .content .txt p:nth-child(3) {
  opacity: 1;
  transition-delay: 1.3s;
  transform: translateY(0);
}

.home-page .container-p3.active .content .txt p:nth-child(4) {
  opacity: 1;
  transition-delay: 1.7s;
  transform: translateY(0);
}

.home-page .container-p3 .content .img {
  opacity: 0;
  background-image: url("Professional-Leak-Detection-Service.be89406f.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 50%;
  height: 600px;
  transition: all .8s;
  position: relative;
  overflow-y: hidden;
  scale: .4;
}

.container-p3.active .content .img {
  opacity: 1;
  scale: 1;
}

.container-p3 .content .img .abimg {
  z-index: 3;
  transition: all .5s;
  position: absolute;
  bottom: -100%;
  left: 50%;
  transform: translate(-50%);
}

.home-page .container-p3 .content .img.active .abimg {
  bottom: 0;
}

.home-page .container-p3 .content .img img {
  border-radius: 10px;
  width: 100%;
  height: 100%;
}

.home-page .container-p4 {
  background-image: url("goldGrid.96b68eb3.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  padding: 100px 7rem;
  position: relative;
}

.home-page .container-p4 .shadow {
  content: "";
  z-index: 1;
  background: #000000a4;
  width: 80%;
  height: 100%;
  transition: all 1s;
  position: absolute;
  top: 0;
  left: -100%;
  transform: skew(318deg);
}

.home-page .container-p4.active .shadow {
  left: -20%;
}

.home-page .container-p4 .txt2 {
  flex-direction: column;
  align-items: start;
  gap: 20px;
  width: 50%;
  display: flex;
}

.home-page .container-p4 h1, .home-page .container-p4 h2 {
  z-index: 3;
  position: relative;
}

.home-page .container-p4 h1 {
  color: #fff;
  opacity: 0;
  transition: all 1s;
  scale: .5;
}

.home-page .container-p4.active h1 {
  opacity: 1;
  scale: 1;
}

.home-page .container-p4 h2 {
  color: #e9cb68;
  opacity: 0;
  line-height: 3rem;
  transition: all 1s .5s;
  transform: translateY(100%);
}

.home-page .container-p4.active h2 {
  opacity: 1;
  transform: translateY(0);
}

.home-page .container-p5 {
  background: #ececec;
  padding: 100px 0;
}

.home-page .container-p5 .content {
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.home-page .container-p5 .row1, .home-page .container-p5 .row2 {
  width: 100%;
}

.home-page .container-p5 i {
  font-size: 30px;
}

.home-page .container-p5 p {
  color: #858585;
  font-size: 18px;
}

.with {
  text-align: center;
  opacity: 0;
  width: 350px;
  transition: all .7s;
  transform: translateX(-100%);
}

.home-page .container-p5.active .row1 .row1-part1-1 {
  opacity: 1;
  transform: translateX(0);
}

.home-page .container-p5.active .row1 .row1-part1-2 {
  opacity: 1;
  transition-delay: .5s;
  transform: translateX(0);
}

.home-page .container-p5.active .row1 .row1-part1-3 {
  opacity: 1;
  transition-delay: .7s;
  transform: translateX(0);
}

.home-page .container-p5.active .row2 .row1-part2-1 {
  opacity: 1;
  transition-delay: 1s;
  transform: translateX(0);
}

.home-page .container-p5.active .row2 .row1-part2-2 {
  opacity: 1;
  transition-delay: 1.3s;
  transform: translateX(0);
}

.home-page .container-p5.active .row2 .row1-part2-3 {
  opacity: 1;
  transition-delay: 1.5s;
  transform: translateX(0);
}

.home-page .container-p6 {
  position: relative;
}

.home-page .container-p6 .absolute {
  content: "";
  z-index: 2;
  background-color: #00000052;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.home-page .container-p6 {
  background-image: url("imageForProjectManagement.f80419d9.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  width: 100%;
  height: 900px;
  position: relative;
  overflow: hidden;
}

.image {
  z-index: 1;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: relative;
}

.image:after {
  content: "";
  z-index: 1;
  background-color: #00000079;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.img1 {
  background-image: url("img1.a6bb3c91.jpg");
}

.img2 {
  background-image: url("img2.2f1a3186.jpg");
}

.img3 {
  background-image: url("img6.78d48e34.webp");
}

.img4 {
  background-image: url("job5.3f96ccd9.jpg");
}

.img5 {
  background-image: url("hjj.961c3a63.jpg");
}

.img6 {
  background-image: url("job4.5b2340ea.jpeg");
}

.home-page .container-p6 .img {
  clip-path: polygon(100% 0, 0 0, 0 98%);
  background-image: url("backgroundImage2.f93746e3.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  width: 100%;
  height: 100%;
  transition: all .8s;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1 !important;
}

.home-page .container-p6 .p1 {
  color: #fff;
  width: 45%;
  position: absolute;
  top: 10%;
  left: 5%;
}

.home-page .container-p6 .p1 h1, .home-page .container-p6 .p2 h1 {
  opacity: 0;
  font-size: 40px;
  transition: all 1s;
}

.home-page .container-p6 .p1 h1 {
  transform: translateX(-100%);
}

.home-page .container-p6.active .p1 h1 {
  opacity: 1;
  transform: translateX(0);
}

.home-page .container-p6 .p2 h1 {
  transform: translateX(100%);
}

.home-page .container-p6.active .p2 h1 {
  opacity: 1;
  transition-delay: .5s;
  transform: translateX(0);
}

.home-page .container-p6 .p1 .mix {
  flex-direction: column;
  align-items: start;
  display: flex;
}

.home-page .container-p6 .p2 .mix {
  flex-direction: column;
  align-items: end;
  display: flex;
}

.home-page .container-p6 .p1 .mix span, .container-p6 .p2 .mix span {
  visibility: hidden;
  border: 1px solid #fff;
  width: 0;
  transition: all .5s;
}

.home-page .container-p6.active .p1 .mix span {
  visibility: visible;
  width: 25%;
  transition-delay: .9s;
}

.home-page .container-p6.active .p2 .mix span {
  visibility: visible;
  width: 25%;
  transition-delay: 1.7s;
}

.home-page .container-p6 .p1 h4, .container-p6 .p2 h4 {
  visibility: hidden;
  font-size: 20px;
  line-height: 50px;
  transition: all 1s;
}

.home-page .container-p6 .p1 h4 {
  transform: translateX(-100%);
}

.home-page .container-p6.active .p1 h4 {
  visibility: visible;
  transition-delay: .5s;
  transform: translateX(0);
}

.home-page .container-p6 .p2 h4 {
  transform: translateX(100%);
}

.home-page .container-p6.active .p2 h4 {
  visibility: visible;
  width: 100%;
  transition-delay: 1s;
  transform: translateX(0);
}

.home-page .container-p6 .p2 {
  color: #fff;
  text-align: end;
  width: 45%;
  position: absolute;
  bottom: 10%;
  right: 5%;
  z-index: 3 !important;
}

.home-page .container-p7 .row {
  opacity: 0;
  border: 1px solid #daa520;
  border-radius: 10px;
  flex-direction: column;
  width: 33.3%;
  height: 477px;
  padding: 20px;
  transition: all .9s;
  display: flex;
  position: relative;
  overflow: hidden;
  transform: translateX(-100%);
  box-shadow: 0 0 15px 5px #daa520;
  text-align: center !important;
}

.home-page .container-p7 .row img {
  border-radius: 5px;
  width: 100%;
  height: 300px;
  transition: all .5s;
}

.home-page .container-p7 .row1 {
  align-items: start;
}

.home-page .container-p7.active .row1 {
  opacity: 1;
  transform: translateX(0);
}

.home-page .container-p7 .row2 {
  text-align: center;
}

.home-page .container-p7.active .row2 {
  opacity: 1;
  transition-delay: .5s;
  transform: translateX(0);
}

.home-page .container-p7 .row3 {
  text-align: right;
  align-items: end;
}

.home-page .container-p7.active .row3 {
  opacity: 1;
  transition-delay: 1s;
  transform: translateX(0);
}

.home-page .container-p7 .row p {
  font-size: 15px;
  line-height: 30px;
}

.home-page .container-p7 .row .appear {
  color: #fff;
  background-color: #000000b0;
  width: 100%;
  height: 100%;
  padding: 20px;
  transition: all .5s;
  position: absolute;
  bottom: -100%;
  left: 0;
}

.home-page .container-p7 .row .appear p {
  font-size: 20px;
  line-height: 35px;
}

.home-page .container-p7 .row:hover .appear {
  bottom: 0;
}

.home-page .container-p7 .row:hover img {
  scale: 3;
}

.btw {
  text-align: center;
  opacity: 0;
  font-size: 25px;
  transition: all .8s;
  transform: translateX(-100%);
}

.btw.active {
  opacity: 1;
  transform: translateX(0);
}

.btw p {
  line-height: 40px;
}

.footer a, .footer {
  color: #d4af37;
}

.footer {
  background: #111;
  margin-top: 100px;
  padding: 40px 20px;
}

.footer-container {
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
}

.footer-logo, .footer-links, .footer-contact {
  flex: 200px;
  margin: 10px;
}

.footer-logo img {
  max-width: 120px;
  margin-bottom: 10px;
}

.footer-logo h3 {
  margin: 5px 0;
  font-size: 20px;
}

.footer-links h4, .footer-contact h4 {
  border-bottom: 2px solid #d4af37;
  margin-bottom: 15px;
  font-size: 18px;
  display: inline-block;
}

.footer-links ul, .footer-contact ul {
  padding: 0;
  list-style: none;
}

.footer-links ul li, .footer-contact ul li {
  margin-bottom: 10px;
  font-size: 14px;
}

.footer-links ul li a {
  color: #d4af37;
  text-decoration: none;
}

.footer-links ul li a:hover {
  color: #fff;
}

.footer-contact ul li i {
  color: #d4af37;
  width: 17px;
  margin-right: 10px;
}

.footer-logo p {
  width: 60%;
}

.footer-bottom {
  text-align: center;
  border-top: 1px solid #333;
  margin-top: 30px;
  padding-top: 10px;
  font-size: 14px;
}

.footer-social-container {
  text-align: center;
  margin-top: 20px;
}

.footer-social {
  justify-content: center;
  gap: 20px;
  margin-top: 10px;
  display: flex;
}

.footer-social a {
  color: #d4af37;
  font-size: 24px;
  transition: all .3s;
}

.footer-social a:hover {
  color: #fff;
}

.scroll-top {
  z-index: 4;
  color: #fff;
  opacity: 1;
  visibility: visible;
  background-color: #000;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  transition: all .5s;
  display: flex;
  position: fixed;
  bottom: 20px;
  right: 20px;
}

.scroll-top.DontShow {
  opacity: 0;
  visibility: hidden;
}

.scroll-top i {
  font-size: 30px;
  transition: all .5s;
  transform: rotate(0);
}

.scroll-top.show i {
  transform: rotate(360deg);
}

.scroll-top.DontShow i {
  transform: rotate(90deg);
}

.aboutUs-page .container-p2 {
  background-color: #f5f5f5;
  padding: 200px 20px;
}

@keyframes fadeIn {
  0% {
    transform: translateX(-100%);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.aboutUs-page .aboutUs-content-p1 {
  text-align: start;
  opacity: 0;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  gap: 40px;
  padding-top: 100px;
  animation-name: fadeIn;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  display: flex;
}

.aboutUs-page .aboutUs-content-p1 li {
  list-style: disc;
}

.aboutUs-page .aboutUs-content-p1 button {
  color: #fff;
  cursor: pointer;
  background: linear-gradient(to right, #000, #333);
  border: none;
  border-radius: 5px;
  width: 150px;
  height: 40px;
  font-size: 15px;
  transition: all .5s;
}

.aboutUs-page .aboutUs-content-p1 button:hover {
  box-shadow: 0 0 10px 5px #000;
}

.aboutUs-page .aboutUs-content-p1 p {
  width: 70%;
  font-size: 1.2rem;
}

.aboutUs-page .aboutUs-content-p1 h1 {
  font-family: Franklin Gothic Medium, Arial Narrow, Arial, sans-serif;
  font-size: 50px;
}

.aboutUs-page .aboutUs-content-p2 {
  text-align: center;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
  padding-top: 100px;
  display: flex;
}

.aboutUs-page .aboutUs-content-p2 p {
  font-size: 20px;
}

.aboutUs-page .aboutUs-content-p2 h2 {
  color: #a3a3a3;
  font-family: Courier New, Courier, monospace;
  font-size: 50px;
  font-weight: 500;
}

.aboutUs-page .container-p3 {
  background-image: url("office-tower-omv-company-modern-architecture-high-rise-3840x2160-7035.168160bf.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 500px;
}

.aboutUs-page .container-p4 .aboutUs-content {
  text-align: center;
  text-align: center;
  opacity: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
  padding: 200px 0;
  transition: all 1s .1s;
  display: flex;
  transform: translateY(-100px);
}

.aboutUs-page .container-p4 .aboutUs-content.active {
  opacity: 1;
  transform: translateY(0);
}

.aboutUs-page .container-p4 .aboutUs-content h1 {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 30px;
  line-height: 55px;
}

.aboutUs-page .container-p4 .aboutUs-content p {
  width: 70%;
  font-size: 1.2rem;
}

.aboutUs-page .container-p4 .aboutUs-content button {
  color: #fff;
  cursor: pointer;
  background: #000;
  border: none;
  border-radius: 5px;
  width: auto;
  height: auto;
  padding: 10px;
  font-family: system-ui;
  font-size: 15px;
  transition: all .5s;
}

.aboutUs-page .container-p4 .aboutUs-content button:hover {
  color: #000;
  background-color: #fff;
  transform: translateY(-5px);
  box-shadow: 0 10px 10px 1px #000;
}

.aboutUs-page .container-p5 {
  background-color: #f5fcc2;
  padding-bottom: 50px;
}

.aboutUs-page .container-p5 .together {
  justify-content: space-between;
  align-items: start;
  display: flex;
}

.aboutUs-page .aboutUs-content-p3 p {
  font-size: 20px;
}

.aboutUs-page .container-p5 .aboutUs-content-p2 {
  text-align: start;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  gap: 40px;
  width: 45%;
  display: flex;
}

.aboutUs-page .container-p5 .aboutUs-content-p3 {
  text-align: start;
  flex-direction: column;
  justify-content: center;
  align-items: end;
  gap: 40px;
  width: 45%;
  padding-top: 100px;
  display: flex;
}

.aboutUs-page .container-p5 .aboutUs-content-p3 li {
  list-style: disc;
}

.aboutUs-page .container-p5 .aboutUs-content-p3 ul {
  flex-direction: column;
  gap: 15px;
  display: flex;
}

.aboutUs-page .container-p6 .aboutUs-content-p1 p {
  width: 100%;
}

.aboutUs-page .aboutUs-content-p2-card {
  background-color: #2b2638;
  border-radius: 5px;
  width: 500px;
  height: 300px;
  font-family: Franklin Gothic Medium, Arial Narrow, Arial, sans-serif;
  position: relative;
}

.aboutUs-page .aboutUs-content-p2-card h3 {
  color: #fff;
  margin-top: 70px;
}

.aboutUs-page .aboutUs-content-p2-card .absolute {
  background-color: #e2e2e2;
  width: 100%;
  height: 170px;
  padding: 20px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.aboutUs-page .aboutUs-content-p2-card .absolute ul {
  text-align: left;
  flex-direction: column;
  align-items: start;
  gap: 15px;
  display: flex;
}

.aboutUs-page .aboutUs-content-p2-card .absolute ul li {
  align-items: center;
  gap: 10px;
  display: flex;
}

.aboutUs-page .aboutUs-content-p2-card .absolute ul li i {
  font-size: 9px;
}

.aboutUs-page .aboutUs-content-p2-card .absolute ul li p {
  font-size: 16px !important;
}

.aboutUs-page .aboutUs-content-p2-card .icons i {
  background-color: #ff0;
  border-radius: 50%;
  outline: 6px solid #fff;
  padding: 20px;
  position: absolute;
  top: -20px;
  left: 50%;
  transform: translateX(-50%);
}

.hambergerMenu {
  opacity: 0;
  background-color: #ebc700;
  width: 100%;
  height: 100%;
  padding: 20px;
  font-family: Franklin Gothic Medium, Arial Narrow, Arial, sans-serif;
  transition: all .8s;
  position: fixed;
  top: 0;
  left: -100%;
}

.hambergerMenu#show {
  opacity: 1;
  left: 0;
}

.hambergerMenu .hambergerMenu-content-menuItems {
  flex-direction: column;
  align-items: start;
  gap: 20px;
  margin-top: 20px;
  padding: 20px;
  display: flex;
}

.hambergerMenu .hambergerMenu-content-menuItems div {
  cursor: pointer;
  border-bottom: 2px solid #000;
  width: 100%;
  padding-bottom: 10px;
}

.hambergerMenu .hambergerMenu-content-menuItems a {
  color: #000;
}

.hambergerMenu .hambergerMenu-content-menuItems h3 {
  font-size: 25px;
  transition: all .5s;
}

.hambergerMenu .hambergerMenu-content-menuItems div:hover h3 {
  transform: translateX(20px);
}

.hambergerMenu .hambergerMenu-content-logo {
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  display: flex;
}

.hambergerMenu .hambergerMenu-content-logo .x {
  flex-direction: column;
  gap: 10px;
  padding-left: 60px;
  display: flex;
  position: relative;
}

.hambergerMenu .hambergerMenu-content-logo .x div {
  border: 2px solid #000;
  width: 35px;
  transition: all .3s;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.hambergerMenu .hambergerMenu-content-logo .x div:first-child {
  transform: rotate(45deg);
}

.hambergerMenu .hambergerMenu-content-logo .x div:nth-child(2) {
  transform: rotate(-45deg);
}

.hambergerMenu .hambergerMenu-content-logo .x#notNormal div:first-child, .hambergerMenu .hambergerMenu-content-logo .x#notNormal div:nth-child(2) {
  transform: rotate(0);
}

.contactUsPage .container-p2 {
  background-image: url("cloud4c-company-contact-1Desktop.92da541b.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100vh;
  margin-top: 50px;
  position: relative;
}

.contactUsPage .container-p3 {
  padding-top: 100px;
}

.contactUsPage .container-p2 .absolute-h1 {
  width: 40%;
  position: absolute;
  top: 50%;
  left: 10%;
}

.contactUsPage .container-p2 .absolute-h1 h1 {
  color: #fff;
  text-shadow: 1px 1px 1px #000;
  font-family: Franklin Gothic Medium, Arial Narrow, Arial, sans-serif;
  font-size: 2rem;
}

.contactUsPage .container-p2 .absolute-h1 h4 {
  color: #fff;
  text-shadow: 1px 1px 1px #000;
  font-family: Franklin Gothic Medium, Arial Narrow, Arial, sans-serif;
  font-size: 1.2rem;
}

.contactUsPage .contactUsPage-container-p3-content {
  padding: 0 40px;
}

.contactUsPage .contactUsPage-container-p3-content-left, .contactUsPage .contactUsPage-container-p3-content-right {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 100px;
  display: flex;
}

.contactUsPage .contactUsPage-container-p3-content-left h3, .contactUsPage .contactUsPage-container-p3-content-right h3 {
  width: 50%;
}

.contactUsPage .sameStyle {
  text-align: center;
  cursor: pointer;
  opacity: 0;
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 25px;
  width: 40%;
  margin-top: 50px;
  padding: 30px;
  transition: all .5s;
  display: flex;
  position: relative;
  transform: translateY(100px);
  box-shadow: 0 0 14px 1px #000;
}

.contactUsPage .sameStyle.active {
  opacity: 1;
  transform: translateY(0);
}

.contactUsPage .sameStyle:hover {
  transform: scale(1.1);
}

.contactUsPage .sameStyle i {
  font-size: 2rem;
}

.contactUsPage .sameStyle h4 {
  font-size: 1.2rem;
}

.contactUsPage .contactUsPage-container-p3-content4 :is(h1, h3, h4, i, a) {
  -webkit-text-fill-color: transparent;
  z-index: 2;
  background: -webkit-linear-gradient(#ffd438, #ee2a7b, #6228d7);
  -webkit-background-clip: text;
  width: 100%;
  position: relative;
}

.contactUsPage .contactUsPage-container-p3-content3 :is(h1, h3, h4, i, a) {
  -webkit-text-fill-color: transparent;
  z-index: 2;
  background: -webkit-linear-gradient(#3e65cf, #08851b);
  -webkit-background-clip: text;
  width: 100%;
  position: relative;
}

.contactUsPage .contactUsPage-container-p3-content2 :is(h1, h3, h4, i, a) {
  -webkit-text-fill-color: transparent;
  z-index: 2;
  background: #08c;
  -webkit-background-clip: text;
  width: 100%;
  position: relative;
}

.contactUsPage .contactUsPage-container-p3-content1 :is(h1, h3, h4, i, a) {
  -webkit-text-fill-color: transparent;
  z-index: 2;
  background: -webkit-linear-gradient(#527288, #2c3e50);
  -webkit-background-clip: text;
  width: 100%;
  position: relative;
}

:is(.contactUsPage .contactUsPage-container-p3-content1, .contactUsPage .contactUsPage-container-p3-content2, .contactUsPage .contactUsPage-container-p3-content3, .contactUsPage .contactUsPage-container-p3-content4) a {
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-weight: 700;
  display: flex;
  font-size: 20px !important;
}

.contactUsPage .contactUsPage-container-p3-content4:hover :is(h1, h3, h4, i, a), .contactUsPage .contactUsPage-container-p3-content3:hover :is(h1, h3, h4, i, a), .contactUsPage .contactUsPage-container-p3-content2:hover :is(h1, h3, h4, i, a), .contactUsPage .contactUsPage-container-p3-content1:hover :is(h1, h3, h4, i, a) {
  -webkit-text-fill-color: transparent;
  background: #fff;
  -webkit-background-clip: text;
  width: 100%;
}

.contactUsPage .contactUsPage-container-p3-content4:after {
  content: "";
  opacity: 0;
  z-index: 1;
  background-image: linear-gradient(to right, #ffd438, #ee2a7b, #6228d7);
  border-radius: 10px;
  width: 100%;
  height: 100%;
  transition: all .5s;
  position: absolute;
  top: 0;
  left: 0;
}

.contactUsPage .contactUsPage-container-p3-content3:after {
  content: "";
  opacity: 0;
  z-index: 1;
  background-image: linear-gradient(to right, #3e65cf, #08851b);
  border-radius: 10px;
  width: 100%;
  height: 100%;
  transition: all .5s;
  position: absolute;
  top: 0;
  left: 0;
}

.contactUsPage .contactUsPage-container-p3-content2:after {
  content: "";
  opacity: 0;
  z-index: 1;
  background: linear-gradient(120deg, #08c, #a5cadd);
  border-radius: 10px;
  width: 100%;
  height: 100%;
  transition: all .5s;
  position: absolute;
  top: 0;
  left: 0;
}

.contactUsPage .contactUsPage-container-p3-content1:after {
  content: "";
  opacity: 0;
  z-index: 1;
  background: linear-gradient(to left, #9bb3c4, #2c3e50);
  border-radius: 10px;
  width: 100%;
  height: 100%;
  transition: all .5s;
  position: absolute;
  top: 0;
  left: 0;
}

.contactUsPage .contactUsPage-container-p3-content4:hover:after, .contactUsPage .contactUsPage-container-p3-content3:hover:after, .contactUsPage .contactUsPage-container-p3-content2:hover:after, .contactUsPage .contactUsPage-container-p3-content1:hover:after {
  opacity: 1;
  width: 100%;
}

.contactUsPage .h1Style {
  opacity: 0;
  transition: all .5s;
  transform: translateY(200px);
}

.contactUsPage .h1Style.active {
  opacity: 1;
  line-height: 41px;
  transition-delay: .3s;
  transform: translateY(0);
}

.contactUsPage .container-p4 {
  height: auto;
}

.contactUsPage .container-p4 .submit {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
  display: flex;
}

.contactUsPage .container-p4 .submit input {
  border-radius: 10px;
  width: 50%;
  padding: 14px;
  font-size: 17px;
}

input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.contactUsPage .container-p4 .submit textarea {
  resize: none;
  border: 2px solid #000;
  border-radius: 10px;
  width: 50%;
  height: 300px;
  padding: 14px;
  font-size: 17px;
}

.contactUsPage .container-p4 .submit input[type="number"] {
  -moz-appearance: textfield;
}

.contactUsPage .container-p4 .submit button {
  color: #fff;
  cursor: pointer;
  background: #000;
  border: none;
  border-radius: 5px;
  width: 150px;
  height: 40px;
  font-family: system-ui;
  font-size: 15px;
  transition: all .5s;
}

.contactUsPage .container-p4 .submit button:hover {
  color: #000;
  background-color: #fff;
  transform: translateY(-5px);
  box-shadow: 0 10px 10px 1px #000;
}

.contactUsPage .container-p4 .submit h3.green {
  color: #00b300;
  font-size: 25px;
}

.contactUsPage .container-p4 .submit h3.red {
  color: red;
  font-size: 25px;
}

.red {
  color: red;
}

.redOutLine {
  outline: 3px solid red;
}

.header #transform {
  justify-content: center;
  align-items: center;
  display: flex;
}

.loginPage {
  height: 100%;
}

.loginPage, .registerPage {
  justify-content: center;
  align-items: center;
  display: flex;
}

:is(.loginPage .loginPage-content .loginPage-content-form, .registerPage .registerPage-content .registerPage-content-form) {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
  display: flex;
}

@keyframes animate {
  from {
    transform: translateY(-100%);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

:is(.loginPage .loginPage-content .loginPage-content-form, .registerPage .registerPage-content .registerPage-content-form) .state input {
  opacity: 0;
  border-radius: 10px;
  width: 50%;
  padding: 14px;
  font-size: 17px;
  transition: all .2s;
  animation-name: animate;
  animation-duration: .7s;
  animation-fill-mode: forwards;
}

:is(.loginPage .loginPage-content .loginPage-content-form, .registerPage .registerPage-content .registerPage-content-form) .state {
  width: 100%;
}

:is(.loginPage .loginPage-content .loginPage-content-form, .registerPage .registerPage-content .registerPage-content-form) input:focus {
  outline: 5px solid #d7ad47;
}

:is(.loginPage .loginPage-content .loginPage-content-form, .registerPage .registerPage-content .registerPage-content-form) .state:nth-child(2) input {
  animation-delay: .2s;
}

.registerPage .registerPage-content .registerPage-content-form .state:nth-child(3) input {
  animation-delay: .3s;
}

.registerPage .registerPage-content .registerPage-content-form .state:nth-child(4) input {
  animation-delay: .5s;
}

.registerPage .registerPage-content .registerPage-content-form .state:nth-child(5) input {
  animation-delay: .7s;
}

.registerPage .registerPage-content .registerPage-content-form .state:nth-child(6) input {
  animation-delay: .9s;
}

.registerPage .registerPage-content .registerPage-content-form .state:nth-child(7) input {
  animation-delay: 1.1s;
}

:is(.loginPage .loginPage-content, .registerPage .registerPage-content) {
  text-align: center;
  width: 50%;
}

:is(.loginPage .loginPage-content .loginPage-content-form, .registerPage .registerPage-content .registerPage-content-form) button {
  color: #fff;
  cursor: pointer;
  opacity: 0;
  background: #000;
  border: none;
  border-radius: 5px;
  width: 150px;
  height: 40px;
  margin-bottom: 20px;
  font-family: system-ui;
  font-size: 15px;
  transition: all .5s;
  animation-name: animate;
  animation-duration: .7s;
  animation-delay: 1s;
  animation-fill-mode: forwards;
}

@keyframes animate2 {
  0% {
    transform: translateY(100%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

:is(.loginPage .loginPage-content, .registerPage .registerPage-content) h4 {
  opacity: 0;
  animation-name: animate2;
  animation-duration: .7s;
  animation-delay: 1s;
  animation-fill-mode: forwards;
}

.registerPage .registerPage-content {
  margin-top: 200px;
}

:is(.loginPage .loginPage-content .loginPage-content-form, .registerPage .registerPage-content .registerPage-content-form) button:hover {
  color: #000;
  background-color: #fff;
  transform: translateY(-5px);
  box-shadow: 0 10px 10px 1px #000;
}

.portal:after {
  content: "";
  z-index: 1;
  background-color: #0000006b;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.portal .portal-content {
  z-index: 2;
  text-align: center;
  background-color: #fff;
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
  padding: 30px;
  display: flex;
  position: fixed;
  top: 50%;
  left: 50%;
  overflow: hidden;
  transform: translate(-50%, -50%);
}

@keyframes animate3 {
  from {
    scale: .5;
  }

  to {
    opacity: 1;
    scale: 1;
  }
}

.portal .portal-content img {
  opacity: 0;
  width: 200px;
  animation-name: animate3;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

.portal .portal-content button {
  color: #fff;
  cursor: pointer;
  opacity: 0;
  background: #000;
  border: none;
  border-radius: 5px;
  width: 150px;
  height: 40px;
  font-family: system-ui;
  font-size: 15px;
  transition: all .5s;
  animation-name: animate2;
  animation-duration: 1s;
  animation-delay: .8s;
  animation-fill-mode: forwards;
}

.portal .portal-content button:hover {
  color: #000;
  background-color: #fff;
  transform: translateY(-5px);
  box-shadow: 0 10px 10px 1px #000;
}

.portal .portal-content h1 {
  opacity: 0;
  animation-name: animate2;
  animation-duration: 1s;
  animation-delay: .5s;
  animation-fill-mode: forwards;
}

.portal .portal-content p {
  opacity: 0;
  animation-name: animate3;
  animation-duration: 1s;
  animation-delay: .5s;
  animation-fill-mode: forwards;
}

.disabled {
  pointer-events: none;
  opacity: .5 !important;
}

.container-p2 .clickToSlide button {
  cursor: pointer;
  color: #fff;
  background-color: #0000;
  border: none;
  width: 15%;
  height: 100%;
  font-size: 30px;
}

.container-p2 .clickToSlide button:first-child:hover :after {
  content: "";
  background: linear-gradient(to left, #000, #00000098, #00000049, #0000);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
}

.container-p2 .clickToSlide button:nth-child(2):hover :after {
  content: "";
  background: linear-gradient(to right, #000, #000000af, #0000008c, #0000);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.container-p2 .clickToSlide button:first-child {
  z-index: 3;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.container-p2 .clickToSlide button:nth-child(2) {
  z-index: 3;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.featured-news {
  background-color: #f8f5e5;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 950px;
  margin: 2rem auto;
  padding: 1rem;
  display: flex;
}

.featured-card {
  flex-direction: column;
  display: flex;
}

.featured-image {
  object-fit: cover;
  border-radius: 8px;
  width: 100%;
  height: 250px;
}

.featured-content {
  padding: 1rem;
}

.featured-content h2 {
  color: #333;
  font-size: 1.8rem;
}

.featured-content p {
  color: #555;
  margin: 1rem 0;
}

.categories {
  text-align: center;
  margin: 2rem 0;
}

.categories h3 {
  margin-bottom: 1rem;
  font-size: 1.5rem;
}

.category-scroll-container {
  white-space: nowrap;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.category-scroll-container:after {
  content: "";
  z-index: 1;
  background: linear-gradient(to right, #fff0, #fff);
  width: 100px;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
}

.category-scroll-container:before {
  content: "";
  z-index: 1;
  background: linear-gradient(to left, #fff0, #fff);
  width: 100px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.categoryAnimation {
  animation: 10s linear infinite scroll;
}

.category-list:hover .categoryAnimation {
  animation-play-state: paused;
}

.category-list {
  display: inline-flex;
}

.category-button {
  color: #fff;
  cursor: pointer;
  background-color: #d4af37;
  border: none;
  border-radius: 4px;
  margin: 0 .5rem;
  padding: .5rem 1rem;
  font-size: .9rem;
  transition: all .2s;
}

.category-button:hover {
  background-color: #b8860b;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-100%);
  }
}

.news-section {
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1.5rem;
  padding: 2rem;
  display: grid;
}

.news-card {
  background-color: #fff;
  border-radius: 8px;
  transition: transform .3s;
  overflow: hidden;
  box-shadow: 0 4px 8px #0000001a;
}

.news-card:hover {
  transform: translateY(-5px);
}

.news-image {
  object-fit: cover;
  width: 100%;
  height: 180px;
}

.news-content {
  padding: 1rem;
}

.news-content h2 {
  margin-bottom: .5rem;
  font-size: 1.5rem;
}

.news-date {
  color: #777;
  font-size: .9rem;
}

.news-description {
  margin: 1rem 0;
}

.read-more {
  color: #007bff;
  font-weight: bold;
  text-decoration: none;
}

.read-more:hover {
  text-decoration: underline;
}

.newsPage {
  margin-top: 150px;
}

.projects-page {
  color: #4a3b21;
  background-color: #f8f5e5;
  max-width: 1800px;
  margin: 150px auto auto;
  padding: 30px;
  font-family: Arial, sans-serif;
}

.page-header {
  text-align: center;
  margin-bottom: 2rem;
}

.page-header h2 {
  color: #b89f5a;
  margin-bottom: .5rem;
  font-size: 2.5rem;
}

.page-header p {
  color: #6c5b3b;
  max-width: 800px;
  margin: auto;
  font-size: 1.1rem;
}

.filter-section {
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 2rem;
  padding: 0 10px;
  display: flex;
}

.filter-button {
  color: #fff;
  cursor: pointer;
  background-color: #b89f5a;
  border: none;
  border-radius: 4px;
  padding: .5rem 1rem;
  font-size: .9rem;
  transition: background-color .3s;
}

.filter-button.active, .filter-button:hover {
  background-color: #d6aa32;
}

.projects-gallery {
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 2rem;
  padding: 0 1rem;
  display: grid;
}

.project-card {
  background-color: #fff8e1;
  border: 1px solid #d6c29b;
  border-radius: 10px;
  min-height: 450px;
  transition: transform .3s, box-shadow .3s;
  overflow: hidden;
  box-shadow: 0 6px 10px #00000026;
}

.project-card:hover {
  transform: translateY(-8px);
  box-shadow: 0 10px 15px #00000040;
}

.project-image {
  object-fit: cover;
  border-bottom: 3px solid #b89f5a;
  width: 100%;
  height: 200px;
}

.project-details {
  text-align: left;
  padding: 1.5rem;
}

.project-details h3 {
  color: #b89f5a;
  margin-bottom: .75rem;
  font-size: 1.5rem;
}

.project-details p {
  color: #4a3b21;
  margin-bottom: 1.25rem;
  font-size: 1rem;
  line-height: 1.6;
}

.project-link {
  color: #fff;
  background-color: #b89f5a;
  border-radius: 4px;
  padding: .5rem 1.5rem;
  font-weight: bold;
  text-decoration: none;
  transition: background-color .3s, transform .3s;
  display: inline-block;
}

.project-link:hover {
  background-color: #d6aa32;
  transform: scale(1.05);
}

.home-page .container-p7.target .content {
  margin-top: 50px;
}

.projects-page #active {
  color: #fff;
  background-color: #ffc218;
}

.empty-alert {
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
  display: flex;
}

.empty-alert i {
  font-size: 110px;
}

@media only screen and (width <= 1650px) {
  .home-page .container-p7.target .content {
    padding: 0 30px;
  }

  .home-page .text h1 {
    font-size: 2rem;
  }

  .home-page .text p {
    font-size: 1.1rem;
  }

  .home-page .text {
    top: 40%;
  }

  .home-page .container-p3 .content .img {
    width: 41%;
    height: 465px;
  }

  .home-page .container-p3 .content .txt {
    width: 50%;
  }

  .home-page .container-p4 h2 {
    width: 77%;
    font-size: 1.2rem;
    line-height: 2.5rem;
  }

  .home-page .container-p7 .row {
    width: 33.3%;
    height: 410px;
  }

  .aboutUs-page .aboutUs-content-p1 {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media only screen and (width <= 1250px) {
  .home-page .container-p6 .p2, .container-p3 .content {
    padding: 10px 20px;
  }

  .home-page .container-p4 {
    background-attachment: unset;
  }

  .home-page .container-p4.active .shadow {
    left: 0;
  }

  .home-page .container-p4 .shadow {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    transform: skew(0deg);
  }

  .home-page .container-p4 .txt2 {
    text-align: center;
    align-items: center;
    width: 100%;
  }

  .home-page .container-p4 h2 {
    width: 100%;
  }

  .home-page .container-p6 .p2, .home-page .container-p7 .content {
    flex-wrap: wrap;
    justify-content: center;
  }

  .home-page .container-p6 .p2, .home-page .container-p5 .row1, .home-page .container-p6 .p2, .home-page .container-p5 .row2 {
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 50px;
  }

  .home-page.container-p5 .content {
    padding: 0 20px;
  }

  .home-page .container-p6 .img, .home-page .container-p6 {
    background-attachment: unset;
  }

  .home-page .container-p6 .img {
    clip-path: inset(0 0 50%);
    background-image: url("backgroundImage2-res.8ab8b73b.jpg");
  }

  .home-page .btw .content {
    padding: 0 20px;
  }

  .home-page .container-p6 .p1, .home-page .container-p6 .p2 {
    width: 95%;
  }

  .home-page .container-p6 .p1 {
    top: 10px;
    left: 20px;
  }

  .home-page .container-p6 .p2 {
    bottom: 10px;
    right: 20px;
  }

  .home-page .container-p7 .row:first-child, .home-page .container-p7 .row:nth-child(2) {
    width: 45%;
  }

  .home-page .container-p7 .row:nth-child(3) {
    width: 100%;
  }

  .aboutUs-page .container-p2, .aboutUs-page .container-p4 .aboutUs-content, .aboutUs-page .container-p5 .together, .aboutUs-page .container-p6 .aboutUs-content {
    padding-left: 20px;
    padding-right: 20px;
  }

  .aboutUs-page .container-p2 .aboutUs-content-p2 {
    flex-wrap: wrap;
    justify-content: center;
    gap: 100px;
  }

  :is(.loginPage .loginPage-content .loginPage-content-form, .registerPage .registerPage-content .registerPage-content-form) .state input {
    width: 70%;
  }

  .no-padding {
    padding: 0 !important;
  }
}

@media only screen and (width <= 1100px) {
  .aboutUs-page .aboutUs-content-p2-card {
    background-color: #2b2638;
    border-radius: 5px;
    width: 90%;
  }

  .aboutUs-page .aboutUs-content-p2 {
    justify-content: center;
  }
}

@media only screen and (width <= 1050px) {
  :is(.loginPage .loginPage-content, .registerPage .registerPage-content) {
    text-align: center;
    width: 80%;
  }

  .portal .portal-content img {
    width: 150px;
  }

  .portal .portal-content {
    top: 60%;
  }
}

@media only screen and (width <= 900px) {
  .aboutUs-page .container-p5 .aboutUs-content-p1 h1 {
    font-size: 30px;
  }

  .contactUsPage .h1Style {
    font-size: 1rem;
  }

  .contactUsPage .sameStyle {
    width: 45%;
  }
}

@media only screen and (width <= 800px) {
  .home-page .container-p3 .content {
    flex-wrap: wrap;
  }

  .home-page .container-p3 .content .img, .home-page .container-p3 .content .txt {
    width: 100%;
  }

  .home-page .container-p3 .content .txt {
    text-align: center;
    align-items: center;
    margin-top: 30px;
  }

  .aboutUs-page .container-p5 .together {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  .aboutUs-page .container-p5 .aboutUs-content-p2 {
    border-bottom: 1px solid #000;
    padding-bottom: 40px;
  }

  .aboutUs-page .container-p5 .aboutUs-content-p2, .aboutUs-page .container-p5 .aboutUs-content-p3 {
    width: 100%;
    font-family: Franklin Gothic Medium, Arial Narrow, Arial, sans-serif;
  }

  .aboutUs-page .container-p5 .aboutUs-content-p3 li {
    border-bottom: 1px solid #999;
    padding: 20px;
    list-style: none;
  }

  .aboutUs-page .container-p4 .aboutUs-content h1 {
    font-size: 30px;
    line-height: 35px;
  }

  .aboutUs-page .container-p6 .aboutUs-content-p1 h1 {
    font-size: 40px;
  }

  .aboutUs-page .aboutUs-content-p1 p {
    width: 100%;
  }

  .contactUsPage .h1Style {
    display: none;
  }

  .contactUsPage .sameStyle {
    width: 90%;
  }

  .contactUsPage :is(.contactUsPage-container-p3-content-left, .contactUsPage-container-p3-content-right) {
    justify-content: center;
  }

  .contactUsPage .container-p2 .absolute-h1 {
    width: 85%;
    top: 30%;
  }

  .contactUsPage .contactUsPage-container-p3-content4 :is(h1, h3, h4, i), .contactUsPage .contactUsPage-container-p3-content1 :is(h1, h3, h4, i), .contactUsPage .contactUsPage-container-p3-content2 :is(h1, h3, h4, i), .contactUsPage .contactUsPage-container-p3-content3 :is(h1, h3, h4, i) {
    width: 100%;
  }

  :is(.loginPage .loginPage-content .loginPage-content-form, .registerPage .registerPage-content .registerPage-content-form) .state input {
    width: 90%;
  }

  .portal .portal-content img {
    width: 120px;
  }

  .portal .portal-content h1 {
    font-size: 27px;
  }

  .clickToSlide {
    display: none;
  }
}

@media (width <= 768px) {
  .page-header h2 {
    font-size: 2rem;
  }

  .footer-logo {
    flex-direction: column;
    align-items: center;
    gap: 1px;
    display: flex;
  }

  .project-details h3 {
    font-size: 1.3rem;
  }

  .project-details p {
    font-size: .95rem;
  }

  .footer-container {
    flex-direction: column;
    align-items: center;
  }

  .footer-logo, .footer-links, .footer-contact {
    text-align: center;
    margin-bottom: 20px;
  }

  .footer-social {
    gap: 10px;
  }

  .empty-alert i {
    font-size: 80px;
  }
}

@media only screen and (width <= 710px) {
  .aboutUs-page .aboutUs-content-p1 h1 {
    font-size: 35px;
  }
}

@media only screen and (width <= 700px) {
  .header-content.flex-alignCenter-spaceBetween {
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
  }
}

@media only screen and (width <= 650px) {
  .home-page .container-p7 .row {
    text-align: center;
    align-items: center;
    gap: 20px;
    width: 100% !important;
  }

  .home-page .container-p6 .p1 h4, .home-page .container-p6 .p2 h4 {
    font-size: 17px;
    line-height: 35px;
  }

  .home-page .btw .content h1 {
    font-size: 30px;
  }

  .home-page .btw p {
    font-size: 23px;
    line-height: 50px;
  }

  .home-page .container-p4 {
    padding: 100px 1rem;
  }

  .home-page .container-p6 {
    background-image: url("imageForProjectManagement-res.16d6d4d8.jpg");
    height: 1000px;
  }

  .portal .portal-content img {
    width: 100px;
  }

  .portal .portal-content h1 {
    font-size: 22px;
  }

  .portal .portal-content {
    width: 80%;
  }

  .header h1 {
    font-size: 1.5rem;
  }

  .home-page .text h1 {
    font-size: 1.3rem;
  }

  .home-page .text p {
    font-size: .9rem;
    line-height: 2rem;
  }

  .home-page .text {
    grid-template-columns: 1fr;
    gap: 10px;
    display: grid;
  }

  .aboutUs-page .aboutUs-content-p1 h1, .aboutUs-page .aboutUs-content-p1 h2 {
    font-size: 25px;
  }

  .img1 {
    background-image: url("img1SM.8ad7e5cd.jpg");
  }
}

@media only screen and (width <= 550px) {
  .home-page .container-p6 .p1 h1, .home-page .container-p6 .p2 h1 {
    opacity: 0;
    font-size: 30px;
  }

  .aboutUs-page .aboutUs-content-p1 h1 {
    text-align: start;
    font-size: 20px;
  }

  .aboutUs-page .aboutUs-content-p1 h2 {
    font-size: 18px;
  }

  .header-menu-btn {
    flex-direction: column;
    gap: 5px;
    display: flex;
  }

  .header-menu-text {
    display: none;
  }

  .aboutUs-page .container-p4 .aboutUs-content {
    padding: 100px 10px;
  }

  .aboutUs-page .container-p4 .aboutUs-content p {
    width: 100%;
  }

  .aboutUs-page .aboutUs-content-p2-card {
    background-color: #2b2638;
    border-radius: 5px;
    width: 90%;
  }

  .aboutUs-page .container-p5 .aboutUs-content-p1 {
    padding-left: 5px;
    padding-right: 5px;
  }

  .home-page .container-p6.active .p1 h1, .home-page .container-p6.active .p2 h1 {
    font-size: 30px;
  }

  .contactUsPage .container-p4 {
    padding-left: 20px;
    padding-right: 20px;
  }

  .contactUsPage .container-p4 .submit input, .contactUsPage .container-p4 .submit textarea {
    width: 90%;
  }

  :is(.loginPage .loginPage-content .loginPage-content-form, .registerPage .registerPage-content .registerPage-content-form) .state input {
    width: 100%;
  }

  .header-content.flex-alignCenter-spaceBetween {
    justify-content: space-between;
  }

  .empty-alert i {
    font-size: 60px;
  }
}

@media (width <= 480px) {
  .page-header p {
    font-size: 1rem;
  }

  .project-details p {
    font-size: .9rem;
  }

  :is(.contactUsPage .contactUsPage-container-p3-content1, .contactUsPage .contactUsPage-container-p3-content2, .contactUsPage .contactUsPage-container-p3-content3, .contactUsPage .contactUsPage-container-p3-content4) a {
    font-size: 17px !important;
  }
}
/*# sourceMappingURL=index.2e1373fe.css.map */
