/* ready to use styles */

.flex-alignCenter {
    display: flex;
    align-items: center;
}

.flex-alignCenter-spaceBetween {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.flex-Start-spaceBetween {
    display: flex;
    align-items: start;
    justify-content: space-between;
}
.flex-column-center {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.gap-10 {
    gap: 10px;
}

.gap-15 {
    gap: 15px;
}
.gap-20 {
    gap:20px;
}
.gap-25 {
    gap: 25px;
}
.gap-30 {
    gap: 30px;
}
.gap-35 {
    gap: 35px;
}
.gap-40 {
    gap: 40px;
}
.gap-45 {
    gap: 45px;
}
.gap-50 {
    gap: 50px;
}
.marginTop-200 {
    margin-top: 200px;
}
.marginTop-300 {
    margin-top: 300px;
}

.z-index {
    z-index: 9999;
    position: relative;
}
.no-padding {
    padding: 0 !important;
}
/* .......................................................................... */

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-decoration: none;
    list-style: none;
}
.wrapper {
    margin: 0 auto;
    max-width: 1170px;
    overflow: hidden;
}
.wrapper2 {
    margin: 0 auto;
    max-width: 1600px;
}
.wrapper-aboutUs {
    margin: 0 auto;
    max-width: 1500px;
    overflow: hidden;
    padding: 0 15px;
}
.header {
    position: fixed;
    background-color: #fff;
    left: 0;
    z-index: 100000;
    width: 100%;
    color: #000;
    transition: all 0.3s;
    box-shadow: 0px 0px 5px 0px #000;
}
.header-menu-btn {
    display: none;
}
.header-menu-btn div {
    border-bottom: 3px solid #000 ;
    width: 20px;
}
.header.active {
    background-color: #fff;
    color: #000;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}
.header .header-logo {
    width: 100px;
    height: 100px;
}

.header .header-logo img {
    width: 100%;
    height: 100%;
}

.home-page  .container-p2 {
    margin-top: 75px;
    position: relative;
    height: 100vh;
    overflow: hidden;
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    overflow: hidden;
}
.c {
    width: 100%;
    transition: all 0.8s;
    position: absolute;
    height: 100%;
}

.home-page  .container-p2 img {
    width: 100%;
    height: auto; 
    object-fit: cover;
}
.header-menu {
    margin-right: 10px;
}
.header-menu h3 {
    color: #000 ;
    transition: all 0.5s;
    cursor: pointer;
    padding: 5px;
    border-radius: 5px;
}
.header-menu a.act h3 {
    box-shadow: 0px 10px 10px 1px #000000eb;
    color: #0081bb ;
    transform: translateY(-5px);
}
.header-menu h3:hover {
    box-shadow: 0px 10px 10px 1px #000000eb;
    color: #0081bb ;
    transform: translateY(-5px);
}
.home-page .text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: transparent;
    color: #fff;
    z-index: 10; 
    text-align: center;
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
}
@keyframes animate1 {
    from {
        transform: translateX(-100%);
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
}
  .text h1 {
    position: relative;
    z-index: 3 ;
    font-size: 3rem;
}
.home-page .img1 .text h1 , .home-page .img1 .text p {
    opacity: 0;
    animation-name: animate1;
    animation-duration: 1s;
    animation-fill-mode: forwards;
}
.home-page  .text p {
    font-size: 1.5rem;
    line-height: 3rem;
}
.home-page .container-p3 {
    margin: 100px 0;
    overflow: hidden;
}
.home-page  .container-p3 .content {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.home-page  .container-p3 .content .txt {
    width: 40%;
    font-size: 20px ;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 20px;
}
.home-page  .container-p3 .content .txt h2 {
    opacity: 0;
    transform: translateY(100%);
    transition: all 1s;
    transition-delay: 0.5s;
}
.home-page  .container-p3 .content .txt p {
    opacity: 0;
    transform: translateY(100%);
    transition: all 1s;
}
.home-page  .container-p3.active .content .txt h2 {
    transform: translateY(0);
    opacity: 1;
}
.home-page  .container-p3.active .content .txt p:nth-child(2) {
    transform: translateY(0);
    opacity: 1;
    transition-delay: 0.9s;
}
.home-page  .container-p3.active .content .txt p:nth-child(3) {
    transform: translateY(0);
    opacity: 1;
    transition-delay: 1.3s;
}
.home-page  .container-p3.active .content .txt p:nth-child(4) {
    transform: translateY(0);
    opacity: 1;
    transition-delay: 1.7s;
}
.home-page  .container-p3 .content .img {
    background-image: url(../../public/Professional-Leak-Detection-Service.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 50%;
    height: 600px;
    position: relative;
    overflow-y: hidden;
    opacity: 0;
    scale: 0.4;
    transition: all 0.8s;
}
.container-p3.active .content .img{
    opacity: 1;
    scale: 1;
}
.container-p3 .content .img .abimg {
    position: absolute;
    bottom: -100%;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 3;
    transition: all 0.5s;
}
.home-page .container-p3 .content .img.active .abimg {
    bottom: 0;
}
.home-page .container-p3 .content .img img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
}
.home-page .container-p4 {
    background-image: url(../../public/goldGrid.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    padding: 100px 7rem;
    position: relative;
}
.home-page .container-p4 .shadow {
    content: "";
    z-index: 1;
    background: #000000a4;
    width: 80%;
    height: 100%;
    position: absolute;
    top: 0;
    left: -100%;
    transform: skew(318deg);
    transition: all 1s;
}
.home-page .container-p4.active .shadow {
    left: -20%;
}
.home-page .container-p4 .txt2 {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 20px;
    width: 50%;
}
.home-page .container-p4 h1 ,.home-page .container-p4 h2 {
    position: relative;
    z-index: 3;
}
.home-page .container-p4 h1  {
    color: #fff;
    opacity: 0;
    scale: 0.5;
    transition: all 1s;
}
.home-page .container-p4.active h1 {
    scale: 1;
    opacity: 1;
}
.home-page .container-p4 h2 {
    color: #e9cb68;
    line-height: 3rem;
    transform: translatey(100%);
    opacity: 0;
    transition: all 1s;
    transition-delay: 0.5s;
}
.home-page .container-p4.active h2 {
    transform: translatey(0);
    opacity: 1;
}
.home-page .container-p5 {
    background: #ececec;
    padding: 100px 0px;
}

.home-page .container-p5 .content {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
}
.home-page .container-p5 .row1 ,.home-page  .container-p5 .row2 {
    width: 100%;
} 

.home-page .container-p5 i {
    font-size: 30px;
}
.home-page .container-p5 p {
    font-size: 18px;
    color: #858585;
}
.with {
    width: 350px;
    text-align: center;
    transform: translateX(-100%);
    opacity: 0;
    transition: all 0.7s;
}
.home-page .container-p5.active .row1 .row1-part1-1 {
    transform: translateX(0);
    opacity: 1;
}
.home-page .container-p5.active .row1 .row1-part1-2 {
    transform: translateX(0);
    transition-delay: 0.5s;
    opacity: 1;
}
.home-page .container-p5.active .row1 .row1-part1-3 {
    transform: translateX(0);
    transition-delay: 0.7s;
    opacity: 1;
}
.home-page .container-p5.active .row2 .row1-part2-1 {
    transform: translateX(0);
    transition-delay: 1s;
    opacity: 1;
}
.home-page .container-p5.active .row2 .row1-part2-2 {
    transform: translateX(0);
    transition-delay: 1.3s;
    opacity: 1;
}
.home-page .container-p5.active .row2 .row1-part2-3 {
    transform: translateX(0);
    transition-delay: 1.5s;
    opacity: 1;
}
.home-page .container-p6 {
    position: relative;
}
.home-page .container-p6 .absolute {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #00000052;
    z-index: 2;
}
.home-page .container-p6 {
    
    background-image: url(../../public/imageForProjectManagement.jpg); 
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    height: 900px;
    width: 100%;
    position: relative;
    overflow: hidden;
}
.image {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    height: 100%;
    width: 100%;
    z-index: 1;
}
.image::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #00000079;
    z-index: 1;
}
.img1 {
    background-image: url(../../public/img1.jpg);
}
.img2 {
    background-image: url(../../public/img2.jpg);
}
.img3 {
    background-image: url(../../public/img6.webp);
}
.img4 {
    background-image: url(../../public/job5.jpg);
}
.img5 {
    background-image: url(../../public/hjj.jpg);
}
.img6 {
    background-image: url(../../public/job4.jpeg);
}

.home-page .container-p6 .img {
    background-image: url(../../public/backgroundImage2.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    clip-path: polygon(100% 0, 0 0, 0 98%);
    height: 100%;
    z-index: 1 !important;
    transition: all 0.8s;
}
.home-page .container-p6 .p1  {
    color: #fff;
    position: absolute;
    top: 10%;
    left: 5%;
    width: 45%;
}
.home-page .container-p6 .p1 h1 ,.home-page .container-p6 .p2 h1{
    font-size: 40px;
    opacity: 0;
    transition: all 1s;
}
.home-page .container-p6 .p1 h1 {
    transform: translateX(-100%);
}
.home-page .container-p6.active .p1 h1 {
    transform: translateX(0);
    opacity: 1;
}
.home-page .container-p6 .p2 h1 {
    transform: translateX(100%);
}
.home-page .container-p6.active .p2 h1 {
    transform: translateX(0);
    transition-delay: 0.5s;
    opacity: 1;
}
.home-page .container-p6 .p1 .mix  {
    display: flex;
    align-items: start;
    flex-direction: column;
}
.home-page .container-p6 .p2 .mix {
    display: flex;
    align-items: end;
    flex-direction: column;
}
.home-page .container-p6 .p1 .mix span ,.container-p6 .p2 .mix span {
    border: 1px solid #fff;
    width: 0;
    visibility: hidden;
    transition: all 0.5s;
}
.home-page .container-p6.active .p1 .mix span {
    width: 25%;
    transition-delay: 0.9s;
    visibility: visible;
}
.home-page .container-p6.active .p2 .mix span {
    width: 25%;
    transition-delay: 1.7s;
    visibility: visible;
}
.home-page .container-p6 .p1 h4 , .container-p6 .p2 h4 {
    font-size: 20px;
    line-height: 50px;
    transition: all 1s;
    visibility: hidden;
}
.home-page .container-p6 .p1 h4 {
    transform: translateX(-100%);
}
.home-page .container-p6.active .p1 h4 {
    visibility: visible;
    transition-delay: 0.5s;
    transform: translateX(0);
}

.home-page .container-p6 .p2 h4 {
    transform: translateX(100%);
}
.home-page .container-p6.active .p2 h4 {
    visibility: visible;
    transform: translateX(0);
    width: 100%;
    transition-delay: 1s;
}
.home-page .container-p6 .p2 {
    color: #fff;
    position: absolute;
    bottom: 10%;
    right: 5%;
    width: 45%;
    text-align: end;
    z-index: 3 !important;
}
.home-page .container-p7 .row {
    width: 33.3%;
    height: 477px;
    border: 1px solid goldenrod;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 15px 5px goldenrod;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: relative;
    opacity: 0;
    transform: translateX(-100%);
    transition: all 0.9s;
    text-align: center !important;
} 
.home-page .container-p7 .row img {
    width: 100%;
    height: 300px;
    border-radius: 5px;
    transition: all 0.5s;
}
.home-page .container-p7 .row1 {
    align-items: start;
} 
.home-page .container-p7.active .row1 {
    opacity: 1;
    transform: translateX(0);

}
.home-page .container-p7 .row2 {
    text-align: center;
}
.home-page .container-p7.active .row2 {
    opacity: 1;
    transform: translateX(0);
    transition-delay: 0.5s;
}
.home-page .container-p7 .row3 {
    align-items: end;
    text-align: right;
}
.home-page .container-p7.active .row3 {
    opacity: 1;
    transform: translateX(0);
    transition-delay: 1s;
}
.home-page .container-p7 .row p {
    line-height: 30px;
    font-size: 15px;
}
.home-page .container-p7 .row .appear {
    position: absolute;
    bottom: -100%;
    left: 0;
    width: 100%;
    height: 100%;
    transition: all 0.5s;
    background-color: #000000b0;
    color: #fff;
    padding: 20px;
    
}
.home-page .container-p7 .row .appear p {
    font-size: 20px;
    line-height: 35px;
}
.home-page .container-p7 .row:hover .appear {
    bottom: 0;
}
.home-page .container-p7 .row:hover img {
    scale: 3;
}
.btw  {
    text-align: center;
    font-size: 25px;
    transform: translateX(-100%);
    opacity: 0;
    transition: all 0.8s;
}
.btw.active  {
   
    transform: translateX(0);
    opacity: 1;
    
}
.btw p {
    line-height: 40px;
}
.footer a , .footer {
    color: #d4af37;
}
.footer {
    background: #111;
    padding: 40px 20px;
    margin-top: 100px;
  }
  .footer-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .footer-logo,
  .footer-links,
  .footer-contact {
    flex: 1 1 200px;
    margin: 10px;
  }
  
  .footer-logo img {
    max-width: 120px;
    margin-bottom: 10px;
  }
  
  .footer-logo h3 {
    font-size: 20px;
    margin: 5px 0;
  }
  
  .footer-links h4,
  .footer-contact h4 {
    font-size: 18px;
    margin-bottom: 15px;
    border-bottom: 2px solid #d4af37;
    display: inline-block;
  }
  
  .footer-links ul,
  .footer-contact ul {
    list-style: none;
    padding: 0;
  }
  
  .footer-links ul li,
  .footer-contact ul li {
    margin-bottom: 10px;
    font-size: 14px;
  }
  
  .footer-links ul li a {
    color: #d4af37;
    text-decoration: none;
  }
  
  .footer-links ul li a:hover {
    color: #fff;
  }
  
  .footer-contact ul li i {
    margin-right: 10px;
    color: #d4af37;
    width: 17px;
  }
  .footer-logo p {
    width: 60%;
  }
  .footer-bottom {
    text-align: center;
    margin-top: 30px;
    border-top: 1px solid #333;
    padding-top: 10px;
    font-size: 14px;
  }
  
  /* Social icons section */
  .footer-social-container {
    text-align: center;
    margin-top: 20px;
  }
  
  .footer-social {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 10px;
  }
  
  .footer-social a {
    color: #d4af37;
    font-size: 24px;
    transition: 0.3s;
  }
  
  .footer-social a:hover {
    color: #fff;
  }
.scroll-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    z-index: 4;
    background-color: #000;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.5s;
    opacity: 1;
    visibility:visible;
}
.scroll-top.DontShow {
    opacity: 0;
    visibility: hidden;
}

.scroll-top i{
    font-size: 30px;
    transform: rotate(0deg); 
    transition: all 0.5s;
}
.scroll-top.show i {
    transform: rotate(360deg);
}
.scroll-top.DontShow i {
    transform: rotate(90deg);
}
.aboutUs-page .container-p2{
    background-color: #f5f5f5;
    padding: 200px 20px;
}
@keyframes fadeIn {
    0% {
        transform: translateX(-100%);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}
.aboutUs-page .aboutUs-content-p1 {
    text-align: start;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    gap: 40px;
    padding-top: 100px;
    display: flex;
    opacity: 0;
    animation-name: fadeIn;
    animation-duration: 1s;
    animation-fill-mode: forwards;
}

.aboutUs-page .aboutUs-content-p1  li {
    list-style: disc;
}
.aboutUs-page .aboutUs-content-p1 button {
    width: 150px;
    height: 40px;
    border: none;
    border-radius: 5px;
    background:  linear-gradient(to right, #000, #333);
    color: #fff;
    font-size: 15px;
    cursor: pointer;
    transition: all 0.5s;
}
.aboutUs-page .aboutUs-content-p1 button:hover {
   box-shadow: 0px 0px 10px 5px #000;
}
.aboutUs-page .aboutUs-content-p1 p {
    width: 70%;
    font-size: 1.2rem;
}
.aboutUs-page .aboutUs-content-p1 h1 {
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font-size: 50px;
}
.aboutUs-page .aboutUs-content-p2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    gap: 30px;
    padding-top: 100px;
    flex-wrap: wrap;
}
.aboutUs-page .aboutUs-content-p2 p {
    font-size: 20px;
}
.aboutUs-page .aboutUs-content-p2 h2 {
    font-size: 50px;
    font-family: 'Courier New', Courier, monospace;
    font-weight: 500;
    color: #a3a3a3;
}
.aboutUs-page .container-p3 {
    background-image: url(../../public/office-tower-omv-company-modern-architecture-high-rise-3840x2160-7035.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 500px;
}

.aboutUs-page .container-p4 .aboutUs-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    gap: 40px;
    padding: 200px 0;
    text-align: center;
    transform: translateY(-100px);
    opacity: 0;
    transition: all 1s;
    transition-delay: 0.1s;
}
.aboutUs-page .container-p4 .aboutUs-content.active {
    opacity: 1;
    transform: translateY(0);
}
.aboutUs-page .container-p4 .aboutUs-content h1 {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 30px;
    line-height: 55px;
}
.aboutUs-page .container-p4 .aboutUs-content p {
    width: 70%;
    font-size: 1.2rem;
}
.aboutUs-page .container-p4 .aboutUs-content button {
    width: auto;
    height: auto;
    border: none;
    border-radius: 5px;
    background:  #000;
    color: #fff;
    font-size: 15px;
    cursor: pointer;
    transition: all 0.5s;
    font-family: system-ui;
    padding: 10px;
}
.aboutUs-page .container-p4 .aboutUs-content button:hover {
   box-shadow: 0px 10px 10px 1px #000;
   transform: translateY(-5px);
   background-color: #fff;
   color: #000;
   
}
.aboutUs-page .container-p5 {
    background-color: #f5fcc2;
    padding-bottom: 50px;
}
.aboutUs-page .container-p5 .together {
    display: flex;
    align-items: start;
    justify-content: space-between ;
}
.aboutUs-page .aboutUs-content-p3 p {
    font-size: 20px;
}
.aboutUs-page .container-p5 .aboutUs-content-p2 {
    display: flex;
    align-items: start;
    justify-content: center;
    flex-direction: column;
    text-align: start;
    gap: 40px;
    width: 45%;
}
.aboutUs-page .container-p5 .aboutUs-content-p3 {
    display: flex;
    align-items: end;
    justify-content: center;
    flex-direction: column;
    text-align: start;
    gap: 40px;
    width: 45%;
    padding-top: 100px;
}

.aboutUs-page .container-p5 .aboutUs-content-p3 li {
    list-style: disc;
}
.aboutUs-page .container-p5 .aboutUs-content-p3 ul {
    display: flex;
    flex-direction: column;
    gap: 15px;
}
.aboutUs-page .container-p6  .aboutUs-content-p1 p {
    width: 100%;
   
}
.aboutUs-page .aboutUs-content-p2-card {
    background-color: #2b2638;
    width: 500px;
    height: 300px;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    border-radius: 5px;
    position: relative;
    
}
.aboutUs-page .aboutUs-content-p2-card h3 {
    color: #fff;
    margin-top: 70px;
}
.aboutUs-page .aboutUs-content-p2-card .absolute {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 170px;
    background-color: #e2e2e2;
    padding: 20px;
}
.aboutUs-page .aboutUs-content-p2-card .absolute ul {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 15px;
    text-align: left;
}
.aboutUs-page .aboutUs-content-p2-card .absolute ul li  {
    display: flex;
    align-items: center;
    gap: 10px;
}
.aboutUs-page .aboutUs-content-p2-card .absolute ul li i {
    font-size: 9px;
}
.aboutUs-page .aboutUs-content-p2-card .absolute ul li p {
    font-size: 16px !important;
}
.aboutUs-page .aboutUs-content-p2-card .icons i {
    position: absolute;
    top: -20px;
    left: 50%;
    transform: translateX(-50%);
    background-color: yellow;
    padding: 20px; 
    border-radius: 50%;
    outline: 6px solid #ffffff;
}
.hambergerMenu {
    position: fixed;
    top: 0;
    left: -100%;
    opacity: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(235, 199, 0);
    padding: 20px;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    transition: all 0.8s;
}
.hambergerMenu#show {
    left: 0;
    opacity: 1;
}
.hambergerMenu .hambergerMenu-content-menuItems {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 20px;
    margin-top: 20px;
    padding: 20px;
}
.hambergerMenu .hambergerMenu-content-menuItems div {
    border-bottom: 2px solid #000;
    width: 100%;
    padding-bottom: 10px;
    cursor: pointer;
}

.hambergerMenu .hambergerMenu-content-menuItems a {
    color: #000;
    
}
.hambergerMenu .hambergerMenu-content-menuItems  h3 {
    font-size: 25px;
    transition: all 0.5s;
}
.hambergerMenu .hambergerMenu-content-menuItems div:hover h3 {
    transform: translateX(20px);
}
.hambergerMenu .hambergerMenu-content-logo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
}
.hambergerMenu .hambergerMenu-content-logo .x  {
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: relative;
    padding-left: 60px;
}
.hambergerMenu .hambergerMenu-content-logo .x div {
    border: 2px solid #000;
    width: 35px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: all 0.3s;
}
.hambergerMenu .hambergerMenu-content-logo .x div:nth-child(1) {
    transform: rotate(45deg);
}
.hambergerMenu .hambergerMenu-content-logo .x div:nth-child(2) {
    transform: rotate(-45deg);
}
.hambergerMenu .hambergerMenu-content-logo .x#notNormal div:nth-child(1) {
    transform: rotate(0deg);
}
.hambergerMenu .hambergerMenu-content-logo .x#notNormal div:nth-child(2) {
    transform: rotate(0deg);
}
.contactUsPage .container-p2 {
    background-image: url(../../public/cloud4c-company-contact-1Desktop.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100vh;
    margin-top: 50px;
    position: relative;
}
.contactUsPage .container-p3 {
   padding-top: 100px;
}
.contactUsPage .container-p2 .absolute-h1 {
    position: absolute;
    top: 50%;
    left: 10%;
    width: 40%;
}
.contactUsPage .container-p2 .absolute-h1 h1 {
    font-size: 2rem;
    color: #fff;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    text-shadow: 1px 1px 1px #000;
}
.contactUsPage .container-p2 .absolute-h1 h4 {
    font-size: 1.2rem;
    color: #fff;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    text-shadow: 1px 1px 1px #000;
}
.contactUsPage .contactUsPage-container-p3-content {
    padding: 0px 40px;
}
.contactUsPage .contactUsPage-container-p3-content-left , .contactUsPage .contactUsPage-container-p3-content-right {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 100px;
    align-items: center;
}
.contactUsPage .contactUsPage-container-p3-content-left h3 , .contactUsPage .contactUsPage-container-p3-content-right h3 {
    width: 50%;
}
.contactUsPage .sameStyle {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 25px;
    margin-top: 50px;
    box-shadow: 0px 0px 14px 1px #000;
    width: 100%;
    width: 40%;
    padding: 30px;
    border-radius: 10px;
    text-align: center;
    transition: all 0.5s;
    cursor: pointer;
    position : relative;
    transform: translateY(100px);
    opacity: 0;
}
.contactUsPage .sameStyle.active {
    transform: translateY(0px);
    opacity: 1;
} 
.contactUsPage .sameStyle:hover {
    transform: scale(1.1);
    
}
.contactUsPage .sameStyle i {
    font-size: 2rem;
}
.contactUsPage .sameStyle h4 {
    font-size: 1.2rem;
}
.contactUsPage .contactUsPage-container-p3-content4 :is(h1 , h3 , h4 , i , a) {
    background: -webkit-linear-gradient( #ffd438, #ee2a7b ,  #6228d7);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    position: relative;
    z-index:2;
    width: 100%;
}
.contactUsPage .contactUsPage-container-p3-content3 :is(h1 , h3 , h4 , i , a) {
    background: -webkit-linear-gradient( #3e65cf, #08851b);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    position: relative;
    z-index:2;
    width: 100%;
}
.contactUsPage .contactUsPage-container-p3-content2 :is(h1 , h3 , h4 , i , a) {
    background: #0088CC;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    position: relative;
    z-index:2;
    width: 100%;
}
.contactUsPage .contactUsPage-container-p3-content1 :is(h1 , h3 , h4 , i , a) {
    background: -webkit-linear-gradient( #527288 ,  #2C3E50);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    position: relative;
    z-index:2;
    width: 100%;
}
:is(.contactUsPage .contactUsPage-container-p3-content1, .contactUsPage .contactUsPage-container-p3-content2, .contactUsPage .contactUsPage-container-p3-content3, .contactUsPage .contactUsPage-container-p3-content4) a {
    font-size: 20px !important;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;
}
.contactUsPage .contactUsPage-container-p3-content4:hover :is(h1 , h3 , h4 , i , a) , .contactUsPage .contactUsPage-container-p3-content3:hover :is(h1 , h3 , h4 , i , a) , .contactUsPage .contactUsPage-container-p3-content2:hover :is(h1 , h3 , h4 , i , a) , .contactUsPage .contactUsPage-container-p3-content1:hover :is(h1 , h3 , h4 , i , a) {
    background: none #fff;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    width: 100%;
}
.contactUsPage .contactUsPage-container-p3-content4::after {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left:0;
    opacity: 0;
    background-image: linear-gradient(to right ,  #ffd438, #ee2a7b ,  #6228d7);
    transition: all 0.5s;
    z-index: 1;
    border-radius: 10px;
}
.contactUsPage .contactUsPage-container-p3-content3::after {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left:0;
    opacity: 0;
    background-image: linear-gradient(to right ,  #3e65cf, #08851b);
    transition: all 0.5s;
    z-index: 1;
    border-radius: 10px;
}
.contactUsPage .contactUsPage-container-p3-content2::after {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left:0;
    opacity: 0;
    background:linear-gradient(120deg, #0088cc, #a5cadd);
    transition: all 0.5s;
    z-index: 1;
    border-radius: 10px;
    
}
.contactUsPage .contactUsPage-container-p3-content1::after {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left:0;
    opacity: 0;
    background:linear-gradient(to left, #9bb3c4 ,  #2C3E50);
    transition: all 0.5s;
    z-index: 1;
    border-radius: 10px;
}
.contactUsPage .contactUsPage-container-p3-content4:hover::after , .contactUsPage .contactUsPage-container-p3-content3:hover::after , .contactUsPage .contactUsPage-container-p3-content2:hover::after , .contactUsPage .contactUsPage-container-p3-content1:hover::after {
    opacity: 1;
    width: 100%;
}
.contactUsPage .h1Style {
    opacity: 0;
    transition: all 0.5s;
    transform: translateY(200px);
} 
.contactUsPage .h1Style.active {
    opacity: 1;
    transform: translateY(0);
    transition-delay: 0.3s;
    line-height: 41px;
} 
.contactUsPage .container-p4 {
    height: auto;
}
.contactUsPage .container-p4 .submit {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
}
.contactUsPage .container-p4 .submit input {
    width: 50%;
    padding: 14px;
    font-size: 17px;
    border-radius: 10px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.contactUsPage .container-p4 .submit textarea {
    resize: none;
    width: 50%;
    height: 300px;
    padding: 14px;
    font-size: 17px;
    border-radius: 10px;
    border: 2px solid #000;
}
.contactUsPage .container-p4 .submit input[type="number"] {
    -moz-appearance: textfield;
}
.contactUsPage .container-p4 .submit button {
    color: #fff;
    cursor: pointer;
    background: #000;
    border: none;
    border-radius: 5px;
    width: 150px;
    height: 40px;
    font-family: system-ui;
    font-size: 15px;
    transition: all .5s;
}
.contactUsPage .container-p4 .submit button:hover {
        color: #000;
        background-color: #fff;
        transform: translateY(-5px);
        box-shadow: 0 10px 10px 1px #000;
}
.contactUsPage .container-p4 .submit h3.green {
    color: rgb(0, 179, 0);
    font-size: 25px;

}
.contactUsPage .container-p4 .submit h3.red {
    color: red;
    font-size: 25px;
}
.red {
    color: red;
}
.redOutLine {
    outline: 3px solid red;
}
.header #transform  {
    display: flex;
    justify-content: center;
    align-items: center;
}
.loginPage {
   height: 100%;
}
.loginPage , .registerPage {
    display:flex ;
    align-items: center;
    justify-content: center;
}
:is(.loginPage .loginPage-content .loginPage-content-form , .registerPage .registerPage-content .registerPage-content-form) {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 30px;
}

@keyframes animate {
    from {
        transform: translateY(-100%);
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}
:is(.loginPage .loginPage-content .loginPage-content-form , .registerPage .registerPage-content .registerPage-content-form) .state input {
    border-radius: 10px;
    width: 50%;
    padding: 14px;
    font-size: 17px;
    opacity: 0;
    animation-name: animate;
    animation-duration: 0.7s;
    animation-fill-mode: forwards;
    transition: all 0.2s;
}
:is(.loginPage .loginPage-content .loginPage-content-form , .registerPage .registerPage-content .registerPage-content-form) .state {
    width: 100%;
}
:is(.loginPage .loginPage-content .loginPage-content-form , .registerPage .registerPage-content .registerPage-content-form) input:focus {
    outline: 5px solid #d7ad47;
}
:is(.loginPage .loginPage-content .loginPage-content-form , .registerPage .registerPage-content .registerPage-content-form) .state:nth-child(2) input {
    animation-delay: 0.2s;
}
.registerPage .registerPage-content .registerPage-content-form .state:nth-child(3) input {
    animation-delay: 0.3s;
}
.registerPage .registerPage-content .registerPage-content-form .state:nth-child(4)  input {
    animation-delay: 0.5s;
}
.registerPage .registerPage-content .registerPage-content-form .state:nth-child(5)  input {
    animation-delay: 0.7s;
}
.registerPage .registerPage-content .registerPage-content-form .state:nth-child(6)  input {
    animation-delay: 0.9s;
}
.registerPage .registerPage-content .registerPage-content-form .state:nth-child(7)  input {
    animation-delay: 1.1s;
}
:is(.loginPage .loginPage-content , .registerPage .registerPage-content) {
    width: 50%; 
    text-align: center;
}
:is(.loginPage .loginPage-content .loginPage-content-form , .registerPage .registerPage-content .registerPage-content-form ) button {
    color: #fff;
    cursor: pointer;
    background: #000;
    border: none;
    border-radius: 5px;
    width: 150px;
    height: 40px;
    font-family: system-ui;
    font-size: 15px;
    transition: all .5s;
    margin-bottom: 20px;
    opacity: 0;
    animation-name: animate;
    animation-duration: 0.7s;
    animation-fill-mode: forwards;
    animation-delay: 1s ;
}

@keyframes animate2 {
    0% {
        transform: translateY(100%);
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

:is(.loginPage .loginPage-content , .registerPage .registerPage-content) h4 {
    animation-name: animate2;
    animation-duration: 0.7s ;
    animation-fill-mode: forwards;
    animation-delay: 1s;
    opacity: 0;
}
.registerPage .registerPage-content {
    margin-top: 200px;

}
:is(.loginPage .loginPage-content .loginPage-content-form , .registerPage .registerPage-content .registerPage-content-form) button:hover {
    color: #000;
    background-color: #fff;
    transform: translateY(-5px);
    box-shadow: 0 10px 10px 1px #000;
}
.portal::after {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #0000006b;
    z-index: 1;
}
.portal .portal-content {
    background-color: #fff;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50% , -50%);
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    gap: 30px;
    padding: 30px;
    overflow: hidden;
    border-radius: 10px;
}
@keyframes animate3 {
    from {
        
        scale: 0.5;
    }
    to {
        
        opacity: 1;
        scale: 1;
    }
}
.portal .portal-content img {
    width: 200px;
    opacity: 0;
    animation-name: animate3;
    animation-duration: 1s;
    animation-fill-mode: forwards;
}
.portal .portal-content button {
    color: #fff;
    cursor: pointer;
    background: #000;
    border: none;
    border-radius: 5px;
    width: 150px;
    height: 40px;
    font-family: system-ui;
    font-size: 15px;
    transition: all .5s;
    animation-name: animate2;
    animation-duration: 1s ;
    animation-fill-mode: forwards;
    animation-delay: 0.8s;
    opacity: 0;
}
.portal .portal-content button:hover {
        color: #000;
        background-color: #fff;
        transform: translateY(-5px);
        box-shadow: 0 10px 10px 1px #000;
}

.portal .portal-content h1 {
    animation-name: animate2;
    animation-duration: 1s ;
    animation-fill-mode: forwards;
    animation-delay: 0.5s;
    opacity: 0;
}
.portal .portal-content p {
    animation-name: animate3;
    animation-duration: 1s ;
    animation-fill-mode: forwards;
    animation-delay: 0.5s;
    opacity: 0;
}
.disabled {
    pointer-events: none;
    opacity: 0.5 !important;
}
.container-p2 .clickToSlide button {
    background-color: transparent;
    border: none;
    font-size: 30px;
    cursor: pointer;
    color: #fff;
    height: 100%;
    width: 15%;
}

.container-p2 .clickToSlide button:nth-child(1):hover ::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to left , #000 , #00000098 , #00000049 , #00000000);
}
.container-p2 .clickToSlide button:nth-child(2):hover ::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right , #000 , #000000af , #0000008c , #00000000);
}
.container-p2 .clickToSlide button:nth-child(1) {
    position: absolute ;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    z-index: 3;
}
.container-p2 .clickToSlide button:nth-child(2) {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    z-index: 3;
}
.featured-news {
    background-color: #f8f5e5;
    border-radius: 8px;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 950px;
    margin: 2rem auto;
    padding: 1rem;
    display: flex;
}
  
  .featured-card {
    display: flex;
    flex-direction: column;
  }
  
  .featured-image {
    width: 100%;
    height: 250px;
    object-fit: cover;
    border-radius: 8px;
  }
  
  .featured-content {
    padding: 1rem;
  }
  
  .featured-content h2 {
    font-size: 1.8rem;
    color: #333;
  }
  
  .featured-content p {
    margin: 1rem 0;
    color: #555;
  }
  
  /* Categories Section */
  .categories {
    text-align: center;
    margin: 2rem 0;
  }
  
  .categories h3 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
  
  .category-scroll-container {
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    position: relative;
  }
  .category-scroll-container::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 100px;
    height: 100%;
    background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
    z-index: 1;
  }
  .category-scroll-container::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100px;
    height: 100%;
    background: linear-gradient(to left, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
    z-index: 1;
  }
  .categoryAnimation {
    animation : scroll 10s linear infinite;
    animation-play-state: running; 
  }
  .category-list:hover .categoryAnimation {
    animation-play-state: paused;
  }
  .category-list {
    display: inline-flex;
  }
  
  .category-button {
    padding: 0.5rem 1rem;
    font-size: 0.9rem;
    background-color: #D4AF37;
    color: white;
    border: none;
    border-radius: 4px;
    margin: 0 0.5rem;
    cursor: pointer;
    transition: all 0.2s;
  }
  
  .category-button:hover {
    background-color: #B8860B;
  }
  
  /* Animation keyframes */
  @keyframes scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  
  /* News Articles Section */
  .news-section {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 1.5rem;
    padding: 2rem;
  }
  
  .news-card {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s;
  }
  
  .news-card:hover {
    transform: translateY(-5px);
  }
  
  .news-image {
    width: 100%;
    height: 180px;
    object-fit: cover;
  }
  
  .news-content {
    padding: 1rem;
  }
  
  .news-content h2 {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
  }
  
  .news-date {
    font-size: 0.9rem;
    color: #777;
  }
  
  .news-description {
    margin: 1rem 0;
  }
  
  .read-more {
    color: #007bff;
    text-decoration: none;
    font-weight: bold;
  }
  
  .read-more:hover {
    text-decoration: underline;
  }
  .newsPage {
    margin-top: 150px;
  }
  /* General styling for the projects page */
.projects-page {
    background-color: #f8f5e5; /* Light gold background */
    color: #4a3b21; /* Darker gold for text */
    font-family: Arial, sans-serif;
    max-width: 1800px;
    margin: auto;
    margin-top: 150px;
    padding: 30px;
}

/* Page header styling */
.page-header {
    text-align: center;
    margin-bottom: 2rem;
}

.page-header h2 {
    font-size: 2.5rem;
    color: #b89f5a; /* Elegant gold for header */
    margin-bottom: 0.5rem;
}

.page-header p {
    font-size: 1.1rem;
    color: #6c5b3b; /* Darker gold */
    max-width: 800px;
    margin: auto;
}

/* Filter section */
.filter-section {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1rem;
    margin-bottom: 2rem;
    padding: 0 10px 0 10px;
}

.filter-button {
    padding: 0.5rem 1rem;
    font-size: 0.9rem;
    background-color: #b89f5a;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.filter-button.active,
.filter-button:hover {
    background-color: #d6aa32; /* Darker gold on hover */
}

/* Projects gallery */
.projects-gallery {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 2rem;
    padding: 0 1rem;
}

/* Project card */
.project-card {
    background-color: #fff8e1;
    border: 1px solid #d6c29b; 
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.15);
    transition: transform 0.3s, box-shadow 0.3s;
    min-height: 450px;
}
.project-card:hover {
    transform: translateY(-8px);
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.25);
}

.project-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-bottom: 3px solid #b89f5a; /* Gold border */
}

/* Project details */
.project-details {
    padding: 1.5rem;
    text-align: left;
}

.project-details h3 {
    font-size: 1.5rem;
    color: #b89f5a;
    margin-bottom: 0.75rem;
}

.project-details p {
    font-size: 1rem;
    color: #4a3b21;
    line-height: 1.6;
    margin-bottom: 1.25rem;
}

/* Project details button */
.project-link {
    display: inline-block;
    padding: 0.5rem 1.5rem;
    background-color: #b89f5a; /* Gold button */
    color: white;
    border-radius: 4px;
    text-decoration: none;
    font-weight: bold;
    transition: background-color 0.3s, transform 0.3s;
}

.project-link:hover {
    background-color: #d6aa32; /* Darker gold on hover */
    transform: scale(1.05);
}
.home-page .container-p7.target .content {
    margin-top: 50px;
}
.projects-page #active {
  background-color: #ffc218;
  color: white;
}
.empty-alert {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    gap: 20px;
    margin-top: 20px;
}
.empty-alert i {
    font-size: 110px;
}


/* .......................responsive........................ */


@media only screen and (max-width : 1650px ) {
    .home-page .container-p7.target .content {
    padding: 0 30px;
}
.home-page .text h1 {
    font-size: 2rem;
}
.home-page .text p {
    font-size: 1.1rem;
}
.home-page .text {
    top: 40%;
}
.home-page .container-p3 .content .img {
    width: 41%;
    height: 465px;
}
.home-page .container-p3 .content .txt {
    width: 50%;
}

.home-page .container-p4 h2 {
    line-height: 2.5rem;
    font-size: 1.2rem;
    width: 77%;
} 
.home-page .container-p7 .row {
    width: 33.3%;
    height: 410px;
}
.aboutUs-page .aboutUs-content-p1 {
    padding-right: 20px;
    padding-left: 20px;
}
}
@media only screen and (max-width : 1250px ) {
    .home-page .container-p6 .p2  , .container-p3 .content {
        padding: 10px 20px;
    }
    .home-page .container-p4 {
        background-attachment: unset;
    }
    .home-page .container-p4.active .shadow {
        left: 0;
    }
    .home-page .container-p4 .shadow {
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        transform: skew(0deg);
    }
    .home-page .container-p4 .txt2 {
        width: 100%;
        text-align: center;
        align-items: center;
    }
    .home-page .container-p4 h2 {
        width: 100%;
    }
    .home-page .container-p6 .p2 , .home-page .container-p7 .content {
        flex-wrap: wrap;
        justify-content: center;
    }
    .home-page .container-p6 .p2 ,.home-page .container-p5 .row1, .home-page .container-p6 .p2 , .home-page .container-p5 .row2 {
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        gap: 50px;
    }
    .home-page.container-p5 .content {
        padding: 0 20px;
    }
    .home-page .container-p6 .img , .home-page .container-p6 {
        background-attachment: unset;
    }
    .home-page .container-p6 .img {
        clip-path: inset(0 0 50% 0);
        background-image: url(../../public/backgroundImage2-res.jpg);
    }
    .home-page .btw .content {
        padding: 0 20px;
    }
    .home-page .container-p6 .p1 , .home-page .container-p6 .p2 {
        width: 95%;
        
    }
    .home-page .container-p6 .p1 {
        top: 10px;
        left: 20px;
    }
    .home-page .container-p6 .p2 {
        bottom:10px;
        right: 20px;
    }
    .home-page .container-p7 .row:nth-child(1) ,.home-page .container-p7 .row:nth-child(2) {
        width: 45%;
    }
    .home-page .container-p7 .row:nth-child(3) {
        width: 100%;
    } 

    .aboutUs-page .container-p2 , .aboutUs-page .container-p4 .aboutUs-content , .aboutUs-page .container-p5 .together , .aboutUs-page .container-p6 .aboutUs-content {
        padding-left: 20px;
        padding-right: 20px;
    }
    .aboutUs-page .container-p2 .aboutUs-content-p2 {
        flex-wrap: wrap;
        justify-content: center;
        gap: 100px;
    }
    :is(.loginPage .loginPage-content .loginPage-content-form, .registerPage .registerPage-content .registerPage-content-form) .state input {
        width: 70%;
    }
    .no-padding {
        padding: 0 !important;
    }
}
@media only screen and (max-width : 1100px ) {
    .aboutUs-page .aboutUs-content-p2-card {
        background-color: #2b2638;
        border-radius: 5px;
        width: 90%;
    }
    .aboutUs-page .aboutUs-content-p2 {
        justify-content: center;
    }
}
@media only screen and (max-width : 1050px ) {
    :is(.loginPage .loginPage-content, .registerPage .registerPage-content) {
        text-align: center;
        width: 80%;
    }
    .portal .portal-content img {
        width: 150px;
    }
    .portal .portal-content {
        top: 60%;
    }
}
@media only screen and (max-width : 900px ) {
    .aboutUs-page .container-p5 .aboutUs-content-p1 h1 {
        font-size: 30px;
    }
    .contactUsPage .h1Style {
        font-size: 1rem;
    }
    .contactUsPage .sameStyle {
        width: 45%;
    }
}
@media only screen and (max-width : 800px ) {
    .home-page  .container-p3 .content {
        flex-wrap: wrap;
    }
    .home-page .container-p3 .content .img , .home-page .container-p3 .content .txt {
        width: 100%;
    }
    .home-page  .container-p3 .content .txt {
        margin-top: 30px;
       align-items: center;
       text-align: center;
    }
    .aboutUs-page .container-p5 .together {
        flex-direction: column;
        gap: 20px;
        align-items: center;
    }
    .aboutUs-page .container-p5 .aboutUs-content-p2 {
        border-bottom: 1px solid #000;
        padding-bottom: 40px;
    }
    .aboutUs-page .container-p5 .aboutUs-content-p2 , .aboutUs-page .container-p5 .aboutUs-content-p3 {
        font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
        width: 100%;
    }
    .aboutUs-page .container-p5 .aboutUs-content-p3 li {
        list-style: none;
        border-bottom: 1px solid #999999;
        padding: 20px;
    }
    .aboutUs-page .container-p4 .aboutUs-content h1 {
        font-size: 30px;
        line-height: 35px;
        
    }
    .aboutUs-page .container-p6 .aboutUs-content-p1 h1 {
        font-size: 40px;
    }
    .aboutUs-page .aboutUs-content-p1 p {
        width: 100%;
    }
    .contactUsPage .h1Style {
        display: none;
    }
    .contactUsPage .sameStyle {
        width: 90%;
    }
    .contactUsPage :is(.contactUsPage-container-p3-content-left , .contactUsPage-container-p3-content-right) {
        justify-content: center;
    }
    .contactUsPage .container-p2 .absolute-h1 {
        width: 85%;
        top: 30%;
    }
    .contactUsPage .contactUsPage-container-p3-content4 :is(h1, h3, h4, i) , .contactUsPage .contactUsPage-container-p3-content1 :is(h1, h3, h4, i) , .contactUsPage .contactUsPage-container-p3-content2 :is(h1, h3, h4, i) , .contactUsPage .contactUsPage-container-p3-content3 :is(h1, h3, h4, i) {
        width: 100%;
    }
    :is(.loginPage .loginPage-content .loginPage-content-form, .registerPage .registerPage-content .registerPage-content-form) .state input {
        width: 90%;
    }
    .portal .portal-content img {
        width: 120px;
    }
    .portal .portal-content h1 {
        font-size: 27px;
    }
    .clickToSlide {
        display: none;
    }
}
@media (max-width: 768px) {
    .page-header h2 {
        font-size: 2rem;
    }
    .footer-logo {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1px;
    }
    .project-details h3 {
        font-size: 1.3rem;
    }
    .project-details p {
        font-size: 0.95rem;
    }
    .footer-container {
        flex-direction: column;
        align-items: center;
      }
    
      .footer-logo,
      .footer-links,
      .footer-contact {
        text-align: center;
        margin-bottom: 20px;
      }
    
      .footer-social {
        gap: 10px;
      }
      .empty-alert i {
        font-size: 80px;
      }
}
@media only screen and (max-width : 710px ) {
    .aboutUs-page .aboutUs-content-p1 h1 {
        font-size: 35px;
    }
}

@media only screen and (max-width : 700px ) {
    .header-content.flex-alignCenter-spaceBetween {
        flex-wrap: wrap;
        justify-content: center;
        gap: 10px;
    } 
}
@media only screen and (max-width : 650px ) {
    .home-page .container-p7 .row {
        width: 100% !important;
       align-items: center;
       text-align: center;
       gap: 20px;
    }
    .home-page .container-p6 .p1 h4,  .home-page .container-p6 .p2 h4{
        font-size: 17px ;
        line-height: 35px;
    }
    .home-page .btw .content h1{
        font-size: 30px;
    }
    .home-page .btw p {
        font-size: 23px;
        line-height: 50px;
    }
    .home-page  .container-p4 {
        padding: 100px 1rem;
    }
    .home-page .container-p6 {
        height: 1000px;
        background-image: url(../../public/imageForProjectManagement-res.jpg);
    }
    .portal .portal-content img {
        width: 100px;
    }
    .portal .portal-content h1 {
        font-size: 22px;
    }
    .portal .portal-content {
        width: 80%;
    }
    .header h1 {
        font-size: 1.5rem;
    }
    .home-page .text h1 {
        font-size: 1.3rem;
    }
    .home-page .text p {
        font-size: 0.9rem;
        line-height: 2rem;
    }
    .home-page .text {
        display: grid;
        grid-template-columns: 1fr  ;
        gap: 10px;
    }
    .aboutUs-page .aboutUs-content-p1 h1 {
        font-size: 25px;
    }
    .aboutUs-page .aboutUs-content-p1 h2 {
        font-size: 25px;
    }
    .img1 {
        background-image: url(../../public/img1SM.jpg);
    }
} 
@media only screen and (max-width : 550px ) {
    .home-page .container-p6 .p1 h1,.home-page .container-p6 .p2 h1 {
        opacity: 0;
        font-size: 30px;

    }
    .aboutUs-page .aboutUs-content-p1 h1 {
        text-align: start;
        font-size: 20px;
    }
    .aboutUs-page .aboutUs-content-p1 h2 {
        font-size: 18px;
    }
    .header-menu-btn {
        display: flex;
        flex-direction: column;
        gap: 5px;
    }
    .header-menu-text {
        display: none;
    }
    .aboutUs-page .container-p4 .aboutUs-content {
       padding: 100px 10px;
      
    }
    .aboutUs-page .container-p4 .aboutUs-content p {
        width: 100%;
    }
    .aboutUs-page .aboutUs-content-p2-card {
        background-color: #2b2638;
        border-radius: 5px;
        width: 90%;
    }
    .aboutUs-page .container-p5 .aboutUs-content-p1 {
        padding-left: 5px;
        padding-right: 5px;
    }
    .home-page .container-p6.active .p1 h1 , .home-page .container-p6.active .p2 h1    {
        font-size: 30px;
    }
    .contactUsPage .container-p4 {
        padding-left: 20px;
        padding-right: 20px;
    }
    .contactUsPage .container-p4 .submit input , .contactUsPage .container-p4 .submit textarea {
        width: 90%;
    }
    :is(.loginPage .loginPage-content .loginPage-content-form, .registerPage .registerPage-content .registerPage-content-form) .state input {
        width: 100%;
    }
            
    .header-content.flex-alignCenter-spaceBetween {
        justify-content: space-between;
    } 
    .empty-alert i {
        font-size: 60px;
    }
}
@media (max-width: 480px) {
    .page-header p {
        font-size: 1rem;
    }
    .project-details p {
        font-size: 0.9rem;
    }
    :is(.contactUsPage .contactUsPage-container-p3-content1, .contactUsPage .contactUsPage-container-p3-content2, .contactUsPage .contactUsPage-container-p3-content3, .contactUsPage .contactUsPage-container-p3-content4) a {
        font-size: 17px !important;
    }
}